
.mx-t3{
  margin-top: -3rem;
}



.jumbotron{
    padding-top: 20px;
    padding-bottom: 0px;
}


.container .jumbotron, .container-fluid .jumbotron {
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}