.redressed-regular {
    font-family: "Redressed", cursive;
    font-weight: 400;
    font-style: normal;
}
.main-div{
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    font-family: 'Redressed';
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.card-container {
    
    height: 100%;
    
   
    background-size: cover;
    background-position: center;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow: hidden;
   margin-left: 15px;
   align-items: center;
   justify-content: center;
}
.profile-header, .content-section {
    /* Semi-transparent white background for the sections */
   margin-top: 0px;
    /* Spacing between sections */
    border-radius: 10px;
}
.profile-header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 20px;
    margin-left: 25px;
}
.profile-header p{
    color: #FFFFFF;
   font-size: medium;
}

.profile-image {
    flex: 0 0 120px; /* Adjust the size of the placeholder as needed */
    height: 140px;
    width: 200px; /* Adjust the height of the placeholder as needed */
     /* Placeholder color, change as needed */
    border-radius: 20px; /* Make it round */
    margin-right: 20px;
    overflow: hidden;
    position: relative;
}
.profile-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}

