/* Main form container */
.checkout-main {
    width: 100%;
    max-width: 500px;
    padding: 40px;
    background-color: #F8F8FF;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin-top: 50px;
    margin: 50px auto; /* Center the container horizontally and add margin from the top */
}

/* Payment Method Image Container */
.checkout-center {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.checkout-center img {
    max-width: 100%;
    height: auto;
}

/* Form styling */
.checkout-card form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    margin-left: 10px;
    display: block;
    font-weight: bold;
}

.input-group input {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
}

.input-group input:focus {
    border-color: orangered;
    outline: none;
}

/* Amount display */
.amount-display {
    margin: 20px 0;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Submit button */
.submit-btn-container button {
    background-color: #0D94FB;
    color: #ffffff;
    border: none;
    padding: 15px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn-container button:hover {
    background-color: #0b85d6;
}

.submit-btn-container button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .checkout-main {
        padding: 20px;
        margin: 10px;
    }

    .input-group label {
        font-size: 14px;
    }

    .input-group input {
        padding: 10px;
        font-size: 14px;
    }

    .submit-btn-container button {
        padding: 10px;
        font-size: 16px;
    }
}
