/* Myprofile.css */
.first-section{
  margin-top: 150px;
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.hover-effect:hover{
  background-color:blue;
  color:yellow;
 }

.partner {
    /* Add any specific styles for the partner div */
    margin-top: 20px;
    padding: 20px 50px;
    border: 1px solid #FF8A00;
    border-radius: 10px;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 0px;
    background: #f4cb8824;
    
  }

  .partner-header h1{
    margin: 0px;
    color: #912524;
    font-weight: 700;
    font-size: 30px;
  }

  .partner-header {
    display: flex;
    align-items: center; 
    margin-bottom: 30px; /* Adjust the margin as needed */
    margin-top: 50px;

  }
  
  .partner-header img {
    height: 35px;
    width: 35px; /* Adjust the width of the image as needed */
    margin-right: 15px; /* Adjust the margin as needed */
  }
  
  .partner-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for the first two, and 2 columns for the last two */
    grid-template-rows: repeat(1, 1fr); /* 16 rows for the first two columns */
    gap: 10px; /* Adjust the gap between grid items as needed */
  }

  

  .partner-grid-container1 {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 2 columns for the first two, and 2 columns for the last two */
    grid-template-rows: repeat(1, 1fr); /* 16 rows for the first two columns */
    gap: 10px; /* Adjust the gap between grid items as needed */
  }


  .partner-grid-container2 {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 2 columns for the first two, and 2 columns for the last two */
    grid-template-rows: repeat(1, 1fr); /* 16 rows for the first two columns */
    gap: 5px; /* Adjust the gap between grid items as needed */
  }

  .partner-grid-container3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for the first two, and 2 columns for the last two */
    grid-template-rows: repeat(1, 1fr); /* 16 rows for the first two columns */
    gap: 5px; /* Adjust the gap between grid items as needed */
  }
  .partner-grid-container3.verified {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 2 columns for the first two, and 2 columns for the last two */
    grid-template-rows: repeat(1, 1fr); /* 16 rows for the first two columns */
    gap: 10px; /* Adjust the gap between grid items as needed */
  }
  .verified-div{
    align-item: center;
    justify-content: center
  }
  .verified-div h1{
    font-size: 20px;
  }

  .verified-div p{
    font-size: 26px;
  }
  
  .partner-grid-item {
    /* Add styling for each grid item */
    /* border: 1px solid #ddd; Example border for visualization */
    padding: 5px;
    text-align: center;
  }


  .partner-title-div{
    color: #B1B1B1;
    font-size: 26px;
    font-weight: 500;
    text-align: left;
    width: 300px;
    word-wrap: break-word;
  }

  

  .partner-value-div{
    color: #FF8A00;
    font-size: 26px;
    font-weight: 500;
    text-align: left;
    margin-left: 50px;
    word-wrap: break-word;
  }

  .partner-value-div.hobbies{
    word-wrap: normal !important;
  }

  

  
  

  
  

  .profile-img.mymatchesslider .slick-slide{
    height: 395px!important;
  }
  

  .profile-img.mymatchesslider .slick-slide img{
    width: 300px !important;
    height: 395px !important
  }

  


  .slick-slide img{
    width: 400px !important;
    height: 720px !important
  }
  
  .slick-slide img.img-mymatches{
    height: 395px !important;
  }
  
  .slick-dots{
    bottom: 10px !important;
  }
  .slick-dots li{
    margin: 0 0 !important
  }

  .slick-dots li button:before{
    opacity: 1 !important;
    color: white !important;
    font-size: 10px !important
  }

  .slick-dots li.slick-active button:before{
    opacity: 1 !important;
    color: #FF8A00 !important;
  }



  @media (min-width: 1200px) and (max-width: 1306px){
    .profile-img.mymatchesslider .slick-slide{
      height: 410px!important;
    }
  
    .profile-img.mymatchesslider .slick-slide img{
      width: 300px !important;
      height: 410px !important
    }
  }

  @media (min-width: 1200px) {
    .profile-img.mymatchesslider.interest .slick-slide{
      height: 520px!important;
    }
  
    .profile-img.mymatchesslider.interest .slick-slide img{
      width: 300px !important;
      height: 520px !important
    }
  }




  /* media queries */
  @media (min-width: 1201px) {
    .partner-value-div{
        width: 200px;
      }
  }

  @media (min-width: 1001px) and (max-width: 1200px){
    .partner-value-div{
        width: 130px;
        margin-left: none;
      }

      
  }

  

  @media (min-width: 865px) and (max-width: 1199px){
  .partner-title-div-basic.matches{
    width: 220px !important;
  }
}

  @media (min-width: 601px) and (max-width: 1000px){
    .basic-info{
      margin-top: 20px;
    }
    .partner-grid-container3{
        display: block;
    }

    .partner-title-div{
        width: none;
        font-size: 20px;
    }
    .partner-value-div{
        width: 80px;
        font-size: 20px;
        margin-left: 20px;
      }


      .partner-title-div-basic{
        font-size: 20px !important;
    }
    .partner-value-div-basic{
        font-size: 20px !important;
      }

      .verified-div p{
        font-size: 20px;
      }

      
  }
 

  @media (min-width: 601px) and (max-width: 865px){
    .basic-info{
      margin-top: 20px;
      margin-right: 30px;
    }
    .first-section{
      display: block !important;
      padding: 0px !important;
      
  }
  .profile-img{
    margin: 0 auto;
  }

  .partner-title-div-basic{
    width: 300px !important;
    font-size: 20px !important;
}
.partner-value-div-basic{
    width: 80px !important;
    font-size: 20px !important;
  }

  .verified-div{
    align-item: center;
    justify-content: left;
  }
  .verified-div h1{
    font-size: 20px;
  }
  .verified-div p{
    font-size: 20px;
  }
  }

  @media (min-width: 300px) and (max-width: 600px) {
    .basic-info{
      margin-top: 20px;
      margin-right: 30px;
    }
    .first-section{
        display: block !important;
        padding: 0px !important;
    }

    .profile-img{
      margin: 0 auto;
    }

    .partner-grid-container{
        display: block;
    }

    .partner-grid-container3{
        display: block;
    }

    .partner-title-div{
        width: 150px;
        font-size: 18px;
    }

    .partner-title-div-basic{
      width: 150px !important;
      font-size: 18px !important;
    }

    .partner-value-div{
        width: 80px;
        font-size: 18px;
        margin-left: 2px;
      }

      .partner-value-div-basic{
        width: 80px !important;
        font-size: 18px !important;
        margin-left: 2px !important;
      }

      .verified-div{
        align-item: center;
        justify-content: left;
      }
      .verified-div h1{
        font-size: 15px;
      }
      .verified-div p{
        font-size: 20px;
      }
  }



  /* basic info */
  .first-section{
    display: flex;
  }

  .basic-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(16, 1fr); /* 16 rows */
    gap: 10px; /* Adjust the gap between grid items as needed */
  }
  
  .basic-grid-item {
    /* Add styling for each grid item */
    border: 1px solid #ddd; /* Example border for visualization */
    padding: 10px;
    text-align: center;
  }

  .partner-title-div-basic{
    color: #B1B1B1;
    font-size: 23px;
    font-weight: 500;
    text-align: left;
    width: 100px;
  }

  

  

  .partner-value-div-basic{
    color: #FF8A00;
    font-size: 23px;
    font-weight: 500;
    text-align: left;
    margin-left: 50px;

  }


  .profile-img{
    width: 400px;
    height: 720px;
  }

  .profile-img.mymatchesslider{
    width: 300px;
    height: 400px;
  }


  @media (max-width: 470px) {
    .profile-img{
      width: 300px;
      height: 500px;
      
    }

    

  .slick-slide img{
    width: 300px !important;
    height: 500px !important
  }

  .partner{
    padding: 10px;
  }
  .verified-div{
    align-item: center;
    justify-content: left;
  }
  .verified-div h1{
    font-size: 15px;
  }

  }

  

  


  /* mymatches */
  
  .my-matches h1{
    color: #FF00A8;
    margin-left: 30px;
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .match-title{
    width:30%;
    font-size: 20px;
    font-weight: 600;
    color: #FBC913;
    font-family: Lemon;
    /* word-wrap: break-word; 
    white-space: normal; */
  }

  

  .match-value{
    color: #FF1F00;
    font-size: 20px;
    font-weight: 600;
    font-family: Lemon;
    margin-left: 90px;
    word-wrap: break-word; 
    white-space: normal;
    /* width: 300px; */
    width:50%;
  }

  .col-sub-div{
    padding: 10px 0px;
  }

  

  
  

  @media (min-width: 992px) and (max-width: 1200px){
    .my-matches h1{
      font-size: 25px;
    }
    .match-title{
      font-size: 17px;
    }
  
    .match-value{
      font-size: 17px;
      word-wrap: break-word; 
    white-space: normal;
    width: 120px;
    }

    .col-sub-div{
      padding: 0px 0px;
    }
  }

  @media (min-width: 501px) and (max-width: 991px){
    .my-matches h1{
      font-size: 25px;
    }
    .match-title{
      font-size: 17px;
      width: 150px;
    }
  
    .match-value{
      font-size: 17px;
      word-wrap: break-word; 
    white-space: normal;
    margin-left: 0px;
    }

    .col-sub-div{
      padding: 0px 0px;
    }
  }

  @media (max-width: 500px){
    .my-matches h1{
      font-size: 25px;
    }
    .match-title{
      font-size: 15px;
      width: 130px;
    }
  
    .match-value{
      font-size: 15px;
      word-wrap: break-word; 
    white-space: normal;
    margin-left: 0px;
    width: 120px;
    }

    .col-sub-div{
      padding: 0px 0px;
    }
  }

  
  .mymatches-grid-title{
    margin-top:10px;
    border: 1px solid rgb(251, 201, 19);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 2px, #FF8A00 0px 2px 2px;
    background: rgba(252, 230, 148, 0.24);
    border-radius: 10px;
  }

  .mymatches-grid-title h1{
    font-size: 28px;
    font-weight: 600;
    color: #912524;
    margin: 10px 20px;
    text-align: left;
    
  }

  .mymatches-grid-title ul{
    padding-left: 10px;
  }

  .mymatches-grid-title ul li{
    padding: 3px 10px;
    font-size: 18px;
    font-weight: 575;
    color: #FF8A00;
    word-wrap: break-word; 
    white-space: normal;
    width: 110px;
    text-align: left;
    
  }

  .mymatchesslider-grid .slick-dots{
    position: static !important;
  }

  .mymatchesslider-grid .slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #FF8A00 !important;
}

  .mymatchesslider-grid .slick-dots li button:before{
    color: #80808085 !important;
    font-size: 10px !important;
  }

  
  @media (min-width: 330px) and (max-width: 500px){
.mymatches-grid-slider .DkxSo{
  position: absolute !important;
  margin-left: 100px;
  margin-top: -820px;
  display: none;
}

.mymatches-grid-slider .cYcnau{
  margin-left: -150px;
  margin-top: -820px;
  display: none;
}
  }


  
  .mymatcher-upper-div-img{
    height:33px !important;
    width:33px !important;
  }
  
  .mymatcher-lower-div-img{
    height:40px !important;
    width:40px !important;
  }
  

  

  .CircularProgressbar{
    height:79px;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #e61969 !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #FFD63F !important ;
  font-weight:700;
  font-size: 19px;
}

.kundli-guna .CircularProgressbar .CircularProgressbar-path {
  stroke: #FF3D60 !important;
}

.kundli-varna .CircularProgressbar .CircularProgressbar-path {
  stroke: #FFA800 !important;
}

.kundli-vashya .CircularProgressbar .CircularProgressbar-path {
  stroke: #FF0000 !important;
}

.kundli-tara .CircularProgressbar .CircularProgressbar-path {
  stroke: #FF7A00 !important;
}

.kundli-yoni .CircularProgressbar .CircularProgressbar-path {
  stroke: #E9C404 !important;
}
.kundli-maitri .CircularProgressbar .CircularProgressbar-path {
  stroke: #20B313 !important;
}
.kundli-gan .CircularProgressbar .CircularProgressbar-path {
  stroke: #2381D9 !important;
}
.kundli-bhakut .CircularProgressbar .CircularProgressbar-path {
  stroke: #3123D9 !important;
}
.kundli-nadi .CircularProgressbar .CircularProgressbar-path {
  stroke: #7323D9 !important;
}

.kundli-display-inner{
  display:flex;
  margin:0 20px;
}



.slick-slide img.telegram {
  height: 35px !important;
  width: 35px !important;
  margin-top: 8px;
}

.slick-slide img.telegram1 {
  height: 35px !important;
  margin-top: 25px;
  width: 35px !important;
}



@media (min-width: 1225px) {
  .mymatches-education{
    margin-top:80px;
  }
}

@media (min-width: 865px) and (max-width:  1224px){
  .mymatches-education{
    margin-top: 120px;
  }
}

@media (max-width:864px) {
  .mymatches-desktop{
    margin-top: 120px;
  }
}

@media (min-width:470px) {
  .kundali-score{
    font-size:20px
  }
  .kundali-score-number{
    height:82px;
    width:82px;
    font-size:20px;
  }
}


@media (max-width:469px) {
  .kundali-score{
    font-size:15px
  }
  .kundali-score-number{
    height:50px;
    width:50px;
    font-size:12px;
  }
}

@media (min-width: 631px){
  .verified-div-chart1{
    width:50%;
  }

  .verified-div-chart2{
    width:50%;
  }
}

@media (max-width: 630px){
  .verified-div-chart{
    flex-direction: column;
  }

  .verified-div-chart1{
    width:100%;
  }

  .verified-div-chart2{
    margin-top: 20px;
    width:100%;
  }
}



/* planSubscriptionDetails */
.plansubscription{
  margin-top:20px;
  padding: 20px;
  border:1px solid #FF8A00;
  border-radius:10px;
  margin-left:100px;
  margin-right:100px;
}

.plansubscription h1{
  color:#828282;
  font-size: 20px;
  font-weight:600;
  text-align:left;
}

.slider-parent{
  position:relative;
}

.buble{
  position:absolute;
}


.kundli-score-popup{
  display:flex;
  padding:0px 30px;

}

.kundli-div-style1{
  width:50%
}
.kundli-div-style2{
  width:50%;
  margin-left:30px
}

@media (max-width:890px){
  .plansubscription{
    margin-left:10px;
    margin-right:10px;
  }

  .kundli-score-popup{
    display:block;
    padding:0px 20px;
  }

  .kundli-div-style1{
    width:100%
  }

  .kundli-div-style2{
    width:100%;
    margin-left:0px
  }
}


img.verifiedimg {
  height: 35px !important;
  width: 35px !important;

}




