@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #testimonials,
  #team,
  #contact,
  #footer
  #chart
  #plans,
  #header {
    width: 100%;
  }
  #services{
    width: 100%
  }

  #portfolio {
    width: 100%;
  }
}
