.form-layout {
    display: flex;
    flex-direction: column;
}

.form-group-bio {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-group-bio label {
    flex: 0 0 150px; /* Label width */
    margin-right: 80px;
    text-align: right;
}

.form-group-bio input,
.form-group-bio select, .form-group-bio textarea,.form-group-bio p{
    flex: 1;
    max-width: 500px;
    padding: 5px 10px; /* Add padding for inputs */
    margin-left: 100px; /* Adjust margin for uniform spacing */
}


.photo-grid {
    display: flex;
    justify-content: space-around;
    gap: 10px; /* Add gap between images */
}

.photo-grid img {
    width: 100px;
    border-radius: 5px; /* Optional: Add rounded corners to images */
}

.download-button {
    padding: 8px 16px;
    background-color: #FF8A00;
    border-radius: 9999px;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and arrow */
    font-size: 1em;
}

.download-button::before {
   /* Unicode for down arrow symbol */
    margin-right: 4px;
    font-size: 1.2em; /* Adjust size as necessary */
}

.theme-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.theme-item.selected {
    border-color: #FF8A00; /* Golden border for selected items */
}

.theme-item:hover {
    transform: scale(1.05);
}

.theme-item h3 {
    margin-top: 10px;
}

.modal-content2 {

    display: flex;
 
    width: 80%;
    height: 80%;
    border: 5px solid #912524;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: white;
}

.modal-image-section {
    margin-right: 30px;
    margin-left: 30px;
}

.modal-image {
    margin-top: 10px;
    width: 250px;
    height: 350px;
}

.modal-buttons {
    margin-top: 10px;
}

.like-button, .share-button {
    margin-right: 15px;
    margin-top: 20px;
    padding: 5px 10px;
    border: none;
    border-radius: 2px;
    background-color: ghostwhite;
    color: black;
    cursor: pointer;
}

.modal-info-section {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.modal-info-content {
    margin-bottom: 20px;
    padding: 20px;
    border: 2px solid black; /* Ensure the border is visible */
    border-radius: 10px;
}

.modal-extra-content {
    padding: 3px;
    /* Add styles for additional content here */
}

.modal-extra-content span{
    font-weight: bold;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    border: 1px solid black; /* Add border to make it stand out */
    cursor: pointer;
}
