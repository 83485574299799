
  .form-website-body{
    background: linear-gradient(180deg, #FCD232 0%, #E02E1B 100%);
    height: 650px;

  }
  
  .website-login-form {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35rem;
    padding: 2.5rem;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    background: #FFFFFF61;
  }
  
  .website-login-form h2 {
    margin: 0 0 1.875rem;
    padding: 0;
    color: #fff;
    text-align: center;
  }
  
  .website-login-form .inputBox {
    position: relative;
  }
  
  .website-login-form .inputBox input {
    margin-left: 40px;
    /* width: 100%; */
    padding: 0.625rem 0;
    font-size: 2rem;
    color: #fff;
    letter-spacing: 0.062rem;
    margin-bottom: 1.875rem;
    border: none;
    border-bottom: 0.065rem solid #777;
    outline: none;
    background: transparent;
  }

  .website-login-form .inputBox img {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.625rem 0;
    font-size: 2rem;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .website-login-form .inputBox label {
    position: absolute;
    top: 0;
    left: 50px;
    padding: 0.625rem 0;
    font-size: 2rem;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }
  
  
  
  .website-login-form input[type="submit"] {
    border: none;
    outline: none;
    color: #fff;
    background-color: #03a9f4;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    border-radius: 0.312rem;
    font-size: 1rem;
  }
  
  .website-login-form input[type="submit"]:hover {
    background-color: #1cb1f5;
  }

  
  
  .forgot-password-popup {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 320px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    padding-top: 0px;
    border-radius: 5px;
    text-align: center;
  }

  .otp-btn-forgot{
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin: auto;
    width: 252px;
    border-radius: 5px;
    background: linear-gradient(180deg,#fcd232,#e02e1b);
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }