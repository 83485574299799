/* body{
    background: rgb(247, 215, 34);
} */

h1 {
  text-align: center;
  margin: 30px auto;
}

.table-bordered {
    border: 1px solid black;
}

.table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border: 1px solid black;
}

.table-bordered > thead > tr > th {
    border: 1px solid black;
}

