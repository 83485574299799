.measure1 {
    max-width: 80% !important;
}

.form-main-register{
    max-width: 80%;
    display: flex !important
}

.form-main-register.pagesix{
    display: block !important;
}

@media (min-width: 701px) and (max-width: 1000px){
    .form-main-register{
        max-width: 70% !important;
    }

}

@media (min-width: 501px) and (max-width: 700px){
    .form-main-register{
        max-width: 80%;
    }
    

}

@media (max-width: 500px){
    .form-main-register{
        max-width: 100%;
    }
    

}


.error-message {
    white-space: pre-line;
  }

  @media (max-width: 1200px){
 .desktop-step{
    display: none !important;
 }

 .form-main-register{
    display: block !important
}


}

@media (min-width: 1200px){
.form-desktop-step{
    width: 100% !important;
    padding-left: 60px !important
}

.heart-img{
    width: 50% !important
}

.google-img{
    width: 40% !important
}
}