/* .container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  .slider {
    position: relative;
    /* width: 230px; */
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 5px;
    height: 8px;
  }
  
  .slider__track {
    background-color: white;
    width: 100%;
    z-index: 1;
  }
  
  .slider__range {
    background-color: #FBC913;
    z-index: 2;
  }
  
  .slider__left-value,
  .slider__right-value {
    color: white;
    font-size: 15px;
    margin-top: 10px;
  }
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
  }
  
  .thumb--left {
    z-index: 3;
  }
  
  .thumb--right {
    z-index: 4;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  @media (min-width: 1260px){
    /* for search */
    .search-range.thumb--left{
      width: 550px !important;
    }

    .search-range.thumb--right{
      width: 550px !important;
    }

    .search-range.slider{
      width: 550px !important;
    }
    
  }

  @media (min-width: 993px) and (max-width: 1259px){
    /* for search */
    .search-range.thumb--left{
      width: 400px !important;
    }

    .search-range.thumb--right{
      width: 400px !important;
    }

    .search-range.slider{
      width: 400px !important;
    }
    
  }

  


  @media (min-width: 993px) {
    .thumb--left{
      width: 320px !important;
    }

    .thumb--right{
      width: 320px !important;
    }

    .slider{
      width: 320px !important;
    }


  }

  @media (min-width: 600px) and (max-width: 992px){
    /* for search */
    .search-range.thumb--left{
      width: 220px !important;
    }

    .search-range.thumb--right{
      width: 220px !important;
    }

    .search-range.slider{
      width: 220px !important;
    }
    
  }

  @media (min-width: 501px) and (max-width: 599px){
    /* for search */
    .search-range.thumb--left{
      width: 150px !important;
    }

    .search-range.thumb--right{
      width: 150px !important;
    }

    .search-range.slider{
      width: 150px !important;
    }
    
  }

  @media (max-width: 500px) {
    .search-range.thumb--left{
      width: 300px !important;
    }

    .search-range.thumb--right{
      width: 300px !important;
    }

    .search-range.slider{
      width: 300px !important;
    }
  }


  @media (max-width: 992px) {
    .thumb--left{
      width: 230px !important;
    }

    .thumb--right{
      width: 230px !important;
    }

    .slider{
      width: 230px !important;
    }
  }