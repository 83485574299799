.rec-pagination{ 
  display: none !important;

}

.react-responsive-modal-modal{
  border-radius: 10px;
}

.modal_new {
  font-size: 45px;
}



@media (min-width: 350px) and (max-width: 500px){
  
  .para-new-btn{
    font-size: 17px !important;
  }
  .fnmixm{
    width: 100% !important;
    margin: 0 2px !important;
  }

}

@media (min-width: 400px) and (max-width: 500px){
  .modal_new{
    font-size: 35px;
  }
}

@media (max-width: 400px) {
  .modal_new {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .item-width{
    width: 80%;
    margin: 15px;
  }
}

@media (min-width: 400px) and (max-width: 520px) {
  .item-width{
    width: 80%;
    margin: 15px
  }
}

@media (min-width: 520px) and (max-width: 768px) {
  .item-width{
    width: 70%;
  }
}


@media (min-width: 768px) and (max-width: 920px) {
  .item-width{
    width: 50%;
  }
}


@media (min-width: 920px) and (max-width: 1250px) {
  .item-width{
    width: 40%;
  }
}

@media (min-width: 1250px) {
  .item-width{
    width: 30%;
  }
}

.payment-method{
  display:flex;
  margin: 100px 50px;
  justify-content: space-around;
}
.payment-method img{
  width:400px !important;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .payment-method {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0px;

}
.payment-method img{
  width:250px !important;
  margin-bottom: 30px;
}
}