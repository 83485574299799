.search-filter{
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    
}

.search-filter button{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    font-size: 26px;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 50px;
    border: 1px solid #FBC913;
    color: #A0A0A0;
    background: none;
}

.mymatches-btn{
    font-size: 30px !important;
}

.search-filter img{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    padding: 10px 5px;
    
}

.search-filter .active {
    background:#FBC913;
    color: white;
}




/* Advance search */
.advance-search {
    padding: 50px;
    padding-top: 0px;
}

.advance-search-btn{
    display: flex;
    margin: auto;
    border: none;
    color: white;
    background: #FBC913;
    border-radius: 10px;
    padding: 5px 80px;
    font-size: 34px;
    font-weight: 700px;
}

.custom-row {
display: flex;
flex-wrap: wrap;
margin-top: 20px;
}

.custom-row label{
    color: #FF8A00;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;

}

.custom-row .form-control{
    /* box-shadow: 0px 4px 4px 0px #FBC91340; */
    /* border: 1px solid #FBC913; */
    color: #AAAAAA;
    font-size: 20px;
    font-weight: 400px;
    height: 40px;

}

.custom-col {
flex: 0 0 50%; /* Each column takes 50% of the row width */
box-sizing: border-box;
padding: 0 15px; /* Optional padding for columns */
}

@media (min-width: 501px) and (max-width: 1400px){
    .search-filter button{
        font-size: 20px;
        font-weight: 600;
    }
}

@media (max-width: 500px) {
.custom-col {
    flex: 0 0 100%; /* Each column takes 100% of the row width */
}

.custom-row{
    margin-top: 0px;
}

.search-rowwise{
    margin-top: 20px;
    margin-bottom: 20px;
}

.advance-search {
    padding: 10px;
    padding-top: 0px;
}

.serach-filter{
    padding: 10px;
}

.search-filter button{
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
}

.mymatches-btn{
    font-size: 22px !important;
}
}

