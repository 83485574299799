.input-range__track {
    border-radius: 0.8rem !important;
    height: 1rem !important;
    background: #ccc !important;
    margin: 0 10px !important;
}

.input-range__slider {
    height: 1.5rem !important;
    margin-top: -1.2rem !important;
    width: 1.5rem !important;
    background: #fff !important;
    border: 1px solid #ccc !important;
}

.input-range__track--active {
    background: #FBC913 !important;
}

.input-range__label {
    color: #323232 !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    margin-top: 25px !important;
}

.input-range__label--max .input-range__label-container {
    display: none !important;
}

.input-range__label--min .input-range__label-container {
    display: none !important;
}