.biodata-container {
    padding: 30px;
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out heading and button */
    width: 100%;
    margin-top: 40px;

    max-width: 1200px;
    position: relative;
    margin-bottom: 40px;
}


.heading-data{
    color: #912524;
      font-weight: bolder;
      font-size: 2.5em;

      position: absolute; /* Center horizontally */
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    
 }
.download-button {
 
    padding: 8px 16px;
    background-color:#FF8A00 ;
    border-radius: 9999px; 
    border: none;
    color: white;
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and arrow */
    font-size: 1em;
}
.download-button::before {
  /* Unicode for down arrow symbol */
    margin-right: 4px;
    font-size: 1.2em; /* Adjust size as necessary */
}
.themes-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Change to 3 columns */
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}

.theme-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.theme-item.selected {
    border-color: #FF8A00; /* Golden border for selected items */
}

.theme-item:hover {
    transform: scale(1.05);
}

.theme-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.theme-item h3 {
    margin-top: 10px;
}
.modal-content2 {

    display: flex;
 
    width: 80%;
    height: 80%;
    border: 5px solid #912524;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: white;
}

.modal-image-section {
    margin-right: 30px;
    margin-left: 30px;
}

.modal-image {
    margin-top: 10px;
    width: 250px;
    height: 350px;
}

.modal-buttons {
    margin-top: 10px;
}

.like-button, .share-button {
    margin-right: 15px;
    margin-top: 20px;
    padding: 5px 10px;
    border: none;
    border-radius: 2px;
    background-color: ghostwhite;
    color: black;
    cursor: pointer;
}

.modal-info-section {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.modal-info-content {
    margin-bottom: 20px;
    padding: 20px;
    border: 2px solid black; /* Ensure the border is visible */
    border-radius: 10px;
}

.modal-extra-content {
    padding: 3px;
    /* Add styles for additional content here */
}

.modal-extra-content span{
    font-weight: bold;
}
