.newsletteroverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .newsletterpopup-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .newsletterpopup-content p {
    margin-bottom: 10px;
  }
  
  .newsletterpopup-content button {
    cursor: pointer;
  }

  @media (min-width: 769px) {
    .newsletter-form{
        width: 450px;
        margin-top: 30px;
    }
    .newsletter-btn{
        float: right;
    }
  }

  @media (max-width: 768px) {
    .newsletterpopup-overlay{
        width: 330px;
        
    }

    .newsletter-form{
        margin-top: 20px;
    }
    
  }